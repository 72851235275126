<template>
    <div class="home-content">
        <qilv-header themes="light"></qilv-header>
        <qilv-main-content :title="$route.meta.title" style="flex:1">
            <router-view></router-view>
        </qilv-main-content>
        <qilv-footer themes="light"></qilv-footer>
    </div>
</template>

<script>
    export default {
        name: "HomeContent",
    }
</script>

<style scoped lang="less">
    .home-content {
        display:flex;
        flex-direction:column;
    }
</style>
